<template>
  <div class="home">
    <div class="title" v-html="data.title"></div>
    <div class="title2">
      <div class="title2_left" style="width: 30px;">
        <img class="img2" src="../assets/logo.png">
      </div>
      <div class="title2_left" style="margin-left: 5px;">
        <span>
          药知事
        </span>
      </div>
      <div class="title2_right" v-html="data.create_time_str"></div>
    </div>
    <div class="description" v-html="data.description"></div>
    <div class="content" v-html="data.content"></div>
    <div class="dbts">
      请进入微信，上滑到顶端，下拉页面进入小程序页,搜索【药知事】，了解更多健康信息。
    </div>
    <div class="dbdhl">
      <span class="dhl_dz" @click="dz()">
        <span class="dhl_dz_span1" v-if="dzState == true"><img class="img2" src="../assets/ydz.png"></span>
        <span class="dhl_dz_span1" v-if="dzState == false"><img class="img2" src="../assets/wdz.png"></span>
        <span>给他点赞</span>
      </span>
      <span class="dhl_zs">
        <span class="dhl_zs_span1">
          <span class="dhl_zs_span2">点赞</span>
          <span class="dhl_zs_span3" v-html="dzCount"></span>
        </span>
        <span class="dhl_zs_span1">
          <span class="dhl_zs_span2">浏览</span>
          <span class="dhl_zs_span3" v-html="llCount"></span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      dzState: false,//是否点赞
      dzCount: 0,//点赞数据
      llCount: 0,//浏览数据
      data: {},
    }
  },
  created() {
    let query = this.$route.query;
    this.getData(query);
  },
  methods: {
    //获取数据
    async getData(query) {
      let that = this;
      await axios.post('/api/actives/api.actives/apiDetail', { id: query.acitveId, withoutcheck: 1, from: 'web' })
        .then(response => {
          // 处理响应数据
          that.data = response.data.data;
          console.log("response数据", that.data);
          //转发阅读记录
          this.getReadRecord(query);
          //获取点赞数据
          that.getDZData();
          //获取浏览数据
          that.getLuLanLiangData();
        })
        .catch(error => {
          // 处理错误
          console.log("error", error);
        });
    },
    //记录阅读
    async getReadRecord(query) {
      await axios.post('/api/actives/api.actives/readRecord', {
        "uid": query.uid,
        "active_id": query.acitveId,
        "read_uid": 0,
        "from": "web",
      })
        .then(response => {
          // 处理响应数据
          console.log("response记录阅读", response);
        })
        .catch(error => {
          // 处理错误
          console.log("error", error);
        });
    },
    //点赞
    async dz() {
      let that = this;
      that.dzState = true;
      if (that.dzState == true) {
        await axios.post('/api/actives/api.actives/collect', {
          "active_id": that.data.id
        })
          .then(response => {
            //处理响应数据
            console.log("response点赞", response);
            //获取点赞数据
            that.getDZData();
            //获取浏览数据
            that.getLuLanLiangData();
          })
          .catch(error => {
            // 处理错误
            console.log("error", error);
          });
      }
    },
    //获取点赞次数
    async getDZData() {
      let that = this;
      await axios.post('/api/actives/api.actives/getCollectCount', {
        "active_id": that.data.id
      })
        .then(response => {
          // 处理响应数据
          console.log("response点赞", response);
          that.dzCount = response.data.data.total;
        })
        .catch(error => {
          // 处理错误
          console.log("error", error);
        });
    },
    //获取活动浏览量
    async getLuLanLiangData() {
      let that = this;
      await axios.post('/api/actives/api.actives/getViews', {
        "active_id": that.data.id
      })
        .then(response => {
          // 处理响应数据
          console.log("response浏览量", response);
          that.llCount = response.data.data.total;
        })
        .catch(error => {
          // 处理错误
          console.log("error", error);
        });
    }
  }
}
</script>
<style scoped>
.home {
  width: 100%;
  text-align: left;
}

.title {
  padding: 10px;
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
}

.title2 {
  padding: 10px;
  height: 40px;
  color: #999999;
  font-size: 15px;
  line-height: 40px;
  border-bottom: 1px solid #999;


}

.title2_left {
  display: inline-block;
  float: left;


}

.img2 {
  position: relative;
  top: 5px;
}

.title2_right {
  display: inline-block;
  float: right;
}

.description {
  background-color: #f8f8f8;
  padding: 10px;
}

.content {
  padding: 10px;
}

.content ::deep img {
  max-width: 100% !important;
}

.dbts {
  background-color: #f3f3f3;
  padding: 10px;
  margin-bottom: 10vh;
}

.dbdhl {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  height: 50px;
  width: 100%;
  font-size: 18px;
  padding-top: 20px;
}

.dhl_dz {
  display: inline-block;
  float: left;
  padding-left: 10px;
  height: inherit;
}

.dhl_dz .dhl_dz_span1 {
  display: inline-block;
  width: 20px;
}

.dhl_zs {
  display: inline-block;
  float: right;
  padding-right: 20px;
  height: inherit;
}

.dhl_zs .dhl_zs_span1 {
  display: inline-block;
  margin: 0px 10px;
}
</style>
